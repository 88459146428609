<template>
  <div class="d-flex flex-column flex-root">
    <!--  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">-->
    <!-- begin:: Header Mobile -->
    <KTHeader
        :menu="true"
    ></KTHeader>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid"
        >
          <div
              class="subheader py-3 py-lg-8 subheader-transparent"
              v-bind:class="subheaderClasses"
              id="kt_subheader"
          >
            <div
                class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
                v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
            >
              <div class="d-flex align-items-baseline mr-5">
                <!--begin::Page Title-->
                <h4 class="subheader-title text-dark font-weight-bold my-0 mr-3">
                  {{ $t("GUEST.TITLE") }}
                </h4>
              </div>
            </div>
          </div>

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
                :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up">
                <router-view/>
              </transition>
            </div>
          </div>
          <KTFooter></KTFooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import KTHeader from "@/view/layout/header/Header.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTHeader,
    KTFooter,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      // this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
<style>
.header-mobile-fixed .wrapper {
  padding-top: 0px
}
</style>
